import * as React from 'react'
import { useRouter } from 'next/router'
import { isAfter, isWithinRange } from 'date-fns'
import * as URLUtils from '@avcan/utils/url'
import * as Generic from '@avcan/prismic/clients/generic'
import Forecasts from 'clients/forecasts'

export function Provider({ product, documents = [], children }) {
    const router = useRouter()
    const value = React.useMemo(createValue, [product, documents])
    function createValue() {
        const { dateIssued, validUntil } = product.report
        const printable = validUntil
            ? isWithinRange(new Date(), dateIssued, validUntil)
            : isAfter(new Date(), dateIssued)

        return {
            product,
            documents: new Map(documents.map(document => [document.uid, document])),
            printable,
            printURL: URLUtils.buildPath(router.asPath, 'print'),
            printing: printable && router.route.includes('/print'),
        }
    }

    return <context.Provider value={value}>{children}</context.Provider>
}

export async function get(id, locale) {
    const promises = [Forecasts.product(id, locale), getDocuments(locale)]
    const [product, documents] = await Promise.all(promises)

    return { product, documents }
}

export function getDocuments(locale) {
    return Generic.getEvery(uids, locale)
}

// Hook: aka getters
export function useForecast() {
    return useContextSlice('product')
}
export function useDocuments() {
    return useContextSlice('documents')
}
export function useDocument(uid) {
    return useDocuments().get(uid)
}
export function useReport() {
    const forecast = useForecast()

    return forecast?.report
}
export function useMedia() {
    const forecast = useForecast()

    return forecast?.media
}
export function useContext() {
    return React.useContext(context)
}
export function usePrintable() {
    return useContextSlice('printable')
}
// TODO: remove this hook. Printing mode removed the tabs,
// but we don't use tabs anymore so regular layout is fine for printing.
export function usePrinting() {
    return useContextSlice('printing')
}
export function usePrintURL() {
    return useContextSlice('printURL')
}
function useContextSlice(name) {
    return useContext()[name]
}

const context = React.createContext()
const uids = [
    'forecast-rss-message',
    'forecast-disclaimer',
    'areas-covered-bulletin',
    'forecast-disclaimer-avalanche-quebec',
    'forecast-disclaimer-parks-canada',
    'forecast-disclaimer-kananaskis',
    'avertissement-previsions-pas-completement-traduites',
    'forecast-info-avalanche-quebec',
]
